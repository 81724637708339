<template>
  <div>
    <div class="header">
      <h1 class="overlay">INQUIRY</h1>
      <div class="headerimage" />
    </div>

    <div class="container contentDesc">
      <div class="title1">
        <h2>
          <strong>고객의 소리</strong>
        </h2>
      </div>
    </div>

    <div class="container">
      <div class="page-content">
        <div class="in-content-center">
          <article class="contact-detail">
            <div class="box-gray">
              <!--              <div class="txt-top">문의하신 내용은 빠른 시간 내에 연락처 또는 이메일로 답변을 드리겠습니다.</div>-->

              <table class="webTable table-join-form table-contact">
                <tbody>
                  <tr>
                    <th>유형 *</th>
                    <td>
                      <b-form-select v-model="selectedType1" :options="space1Options"></b-form-select>
                    </td>
                  </tr>
                  <tr>
                    <th>분류 *</th>
                    <td>
                      <b-form-select v-model="selectedType2" :options="space2Options"></b-form-select>
                    </td>
                  </tr>
                  <tr>
                    <th>내용 *</th>
                    <td>
                      <textarea
                        class="textarea t-medium"
                        rows="7"
                        :placeholder="descplaceholder"
                        v-model="desc"
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <th>첨부파일</th>
                    <td colspan="3">
                      <div class="custom-file">
                        <b-form-file
                          v-model="attachedFile"
                          placeholder="파일을 등록해주세요"
                          browse-text="찾아보기"
                          accept=".jpg, .gif, .pdf"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th></th>
                    <td colspan="3" class="info-text pt-2 pl-1">
                      * 파일 확장자가 jpg,gif,pdf인 파일만 등록하실수 있습니다.<br />
                      * 파일 업로드시 용량은 10MB를 넘을 수 없습니다.
                    </td>
                  </tr>
                  <tr>
                    <th>성명 *</th>
                    <td>
                      <b-form-input autocomplete="off" type="text" placeholder="이름 입력" v-model="userName" />
                    </td>
                  </tr>
                  <tr>
                    <th>이메일 *</th>
                    <td>
                      <ValidationProvider rules="required|email" name="Email" v-slot="{errors}">
                        <b-input-group>
                          <b-form-input
                            autocomplete="off"
                            :state="errors.length === 0"
                            required
                            type="email"
                            v-model="userEmail"
                            :disabled="sendEmailAuth || chkEmailAuth"
                          />
                          <b-input-group-append v-if="sendEmailAuth === false">
                            <b-button class="py-0" variant="primary" :disabled="sendingEmail" @click="sendEmailAuthCode"
                              >인증번호 받기
                            </b-button>
                          </b-input-group-append>
                          <b-input-group-append v-if="sendEmailAuth === true">
                            <b-button class="py-0" variant="primary" @click="resetEmail">이메일 재입력</b-button>
                          </b-input-group-append>
                        </b-input-group>
                        <b-form-invalid-feedback :state="errors.length === 0"
                          >이메일 형식에 맞게 입력해주세요
                        </b-form-invalid-feedback>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <th>이메일 인증</th>
                    <td>
                      <b-input-group>
                        <b-form-input autocomplete="off" v-model="emailAuthCode" />
                        <b-input-group-append>
                          <b-button class="py-0" variant="primary" @click="checkEmailAuthCode" :disabled="chkEmailAuth"
                            >인증
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </td>
                  </tr>
                  <tr>
                    <th>연락처 *</th>
                    <td>
                      <!--                      <b-form-input type="text" placeholder="-없이 입력해 주세요" v-model="userPhone" />-->
                      <FormTel autocomplete="off" v-model="userPhone" placeholder="-없이 입력해 주세요"></FormTel>
                    </td>
                  </tr>
                  <tr>
                    <th height="120px"></th>
                    <td class="table-footer">
                      <button class="inguiry-button btn btn-block" @click="sendMail" :disabled="sendingMail">
                        {{ sendingMail ? '전송중...' : '접수하기' }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table class="mobTable table-join-form">
                <tbody>
                  <tr>
                    <th>유형 *</th>
                  </tr>
                  <tr>
                    <td>
                      <b-form-select v-model="selectedType1" :options="space1Options"></b-form-select>
                    </td>
                  </tr>
                  <tr>
                    <th>분류 *</th>
                  </tr>
                  <tr>
                    <td>
                      <b-form-select v-model="selectedType2" :options="space2Options"></b-form-select>
                    </td>
                  </tr>
                  <tr>
                    <th>내용 *</th>
                  </tr>
                  <tr>
                    <td>
                      <textarea
                        class="textarea t-medium"
                        rows="7"
                        :placeholder="descplaceholder"
                        v-model="desc"
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <th>첨부파일</th>
                  </tr>
                  <tr>
                    <td>
                      <div class="custom-file">
                        <b-form-file
                          v-model="attachedFile"
                          placeholder="파일을 등록해주세요"
                          browse-text="찾아보기"
                          accept=".jpg, .gif, .pdf"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="info-text pt-2 pl-1">
                      * 파일 확장자가 jpg,gif,pdf인 파일만 등록하실수 있습니다.<br />
                      * 파일 업로드시 용량은 10MB를 넘을 수 없습니다.
                    </td>
                  </tr>
                  <tr>
                    <th>성명 *</th>
                  </tr>
                  <tr>
                    <td>
                      <b-form-input autocomplete="off" type="text" placeholder="이름 입력" v-model="userName" />
                    </td>
                  </tr>
                  <tr>
                    <th>이메일 *</th>
                  </tr>
                  <tr>
                    <td>
                      <ValidationProvider rules="required|email" name="Email" v-slot="{errors}">
                        <b-input-group>
                          <b-form-input
                            autocomplete="off"
                            :state="errors.length === 0"
                            required
                            type="email"
                            v-model="userEmail"
                            :disabled="sendEmailAuth || chkEmailAuth"
                          />
                          <b-input-group-append v-if="sendEmailAuth === false">
                            <b-button class="py-0" variant="primary" :disabled="sendingEmail" @click="sendEmailAuthCode"
                              >인증번호 받기
                            </b-button>
                          </b-input-group-append>
                          <b-input-group-append v-if="sendEmailAuth === true">
                            <b-button class="py-0" variant="primary" @click="resetEmail">이메일 재입력</b-button>
                          </b-input-group-append>
                        </b-input-group>
                        <b-form-invalid-feedback :state="errors.length === 0"
                          >이메일 형식에 맞게 입력해주세요
                        </b-form-invalid-feedback>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <th>이메일 인증</th>
                  </tr>
                  <tr>
                    <td>
                      <b-input-group>
                        <b-form-input autocomplete="off" v-model="emailAuthCode" />
                        <b-input-group-append>
                          <b-button class="py-0" variant="primary" @click="checkEmailAuthCode" :disabled="chkEmailAuth"
                            >인증
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </td>
                  </tr>
                  <tr>
                    <th>연락처 *</th>
                  </tr>
                  <tr>
                    <td>
                      <!--                      <b-form-input type="text" placeholder="-없이 입력해 주세요" v-model="userPhone" />-->
                      <FormTel autocomplete="off" v-model="userPhone" placeholder="-없이 입력해 주세요"></FormTel>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="2" class="table-footer" height="120px">
                      <button class="inguiry-button btn btn-block" @click="sendMail" :disabled="sendingMail">
                        {{ sendingMail ? '전송중...' : '접수하기' }}
                      </button>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--/.box-gray-->
          </article>
        </div>
        <!--/.in-content-center-->
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import {DateUtil} from '@/util/DateUtil';
import axios from 'axios';
import FormTel from '@/components/forms/FormTel';
import {loginService} from '@/services/login.service';
import GeneralUtil from '@/util/GeneralUtil';

export default {
  name: 'Qnacontact',
  components: {
    FormTel,
  },
  mixins: [GeneralUtil],
  data() {
    return {
      userName: '',
      userEmail: '',
      userPhone: '',
      desc: '',
      selectedType1: '',
      space1Options: ['문의', '칭찬', '불평', '제안', '기타'],
      selectedType2: '',
      space2Options: ['객실', '골프', '식음료', '기타'],
      attachedFile: null,
      descplaceholder:
        '요청사항이 있으시면 작성해주세요.\n다만, 상황에 따라 요청사항은 수용 불가능 할 수 있습니다.\n개인정보가 포함되지 않도록 주의해주세요.',
      chkEmailAuth: false,
      emailAuthCode: '',
      sendingEmail: false,
      sendEmailAuth: false,
      sendingMail: false,
    };
    // return {
    //   userName: '장동훈',
    //   userEmail: 'inotiof@naver.com',
    //   userPhone: '01092202010',
    //   desc: '내용1 \n 내용2 \n 내용3',
    //   selectedType1: '기타',
    //   space1Options: ['문의', '칭찬', '불평', '제안', '기타'],
    //   selectedType2: '식음료',
    //   space2Options: ['객실', '골프', '식음료', '기타'],
    //   attachedFile: null,
    //   descplaceholder:
    //     '요청사항이 있으시면 작성해주세요.(다만, 상황에 따라 요청사항은 수용 불가능 할 수 있습니다. 개인정보가 포함되지 않도록 주의해주세요.',
    // };
  },
  methods: {
    async checkEmailAuthCode() {
      if (this.userEmail === '') {
        this.$bvModal.msgBoxOk('이메일주소를 입력하세요', {
          title: '이메일주소 입력필요',
          okVariant: 'danger',
          centered: true,
        });
        return;
      }
      if (this.emailAuthCode === '') {
        this.$bvModal.msgBoxOk('인증코드를 입력하세요', {
          title: '인증코드입력필요',
          okVariant: 'danger',
          centered: true,
        });
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      const popupTitle = '이메일 인증';
      let errorMessage = '';
      await loginService
        .checkEmailAuthCode(this.emailAuthCode)
        .then(response => {
          this.$bvModal
            .msgBoxOk(`${popupTitle} 성공`, {
              title: '성공',
              okVariant: 'success',
              centered: true,
            })
            .then(value => {
              this.chkEmailAuth = true;
            });
        })
        .catch(function (error) {
          console.log(error);
          if (error.response) {
            if (error.response.status === 404) {
              errorMessage = '인증번호가 일치하지 않습니다.';
            } else if (error.response.status === 409) {
              errorMessage = "만료된 인증번호입니다. <br>재시도 하시려면 '인증번호 받기'를 눌러주세요.";
            } else {
              errorMessage = `${popupTitle} 서버 오류발생`;
            }
          } else if (error.request) {
            errorMessage = `${popupTitle} 요청 오류발생`;
          } else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            errorMessage = `${popupTitle} 요청 오류발생`;
            console.log('Error', error.message);
          }
          //console.log(error.config);
          self.$bvModal.msgBoxOk(self.htmlToVNode(errorMessage), {
            title: `${popupTitle} 오류발생`,
            okVariant: 'danger',
            centered: true,
          });
          self.chkEmailAuth = false;
        });
    },
    async resetEmail() {
      this.chkEmailAuth = false;
      this.sendEmailAuth = false;
      this.emailAuthCode = '';
      this.userEmail = '';
    },
    async sendEmailAuthCode() {
      if (this.userEmail === '') {
        this.$bvModal.msgBoxOk('이메일 입력하세요', {
          title: '이메일주소 입력필요',
          okVariant: 'danger',
          centered: true,
        });
        return;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        this.sendingEmail = true;
        loginService
          .sendEmailAuthCode(this.userEmail)
          .then(response => {
            if (response.status == 200) {
              this.$bvModal.msgBoxOk('이메일 발송성공 이메일에서 인증코드를 확인후 입력해주세요', {
                title: '성공',
                okVariant: 'success',
                centered: true,
              });
              this.chkEmailAuth = false;
              this.sendEmailAuth = true;
            } else {
              this.$bvModal
                .msgBoxOk('이메일 발송실패', {
                  title: '오류발생',
                  okVariant: 'danger',
                  centered: true,
                })
                .then(value => {
                  this.chkEmailAuth = false;
                });
            }
          })
          .catch(function (error) {
            console.log(error);
            self.$bvModal.msgBoxOk('이메일 발송실패', {
              title: '오류발생',
              okVariant: 'danger',
              centered: true,
            });
            self.chkEmailAuth = false;
          })
          .finally(() => {
            this.sendingEmail = false;
          });
      }
    },
    async sendMail() {
      this.sendingMail = true;
      let tempUpdate = true;
      let notInput = '';

      if (this.userPhone === '' || this.userPhone == null) {
        tempUpdate = false;
        notInput = '연락처';
      }
      if (this.userEmail === '' || this.userEmail == null) {
        tempUpdate = false;
        notInput = '이메일';
      }
      if (this.chkEmailAuth === false) {
        tempUpdate = false;
        notInput = '이메일 인증코드';
      }
      if (this.userName === '' || this.userName == null) {
        tempUpdate = false;
        notInput = '성명';
      }
      if (this.desc === '' || this.desc == null) {
        tempUpdate = false;
        notInput = '내용';
      }
      if (this.selectedType2 === '' || this.selectedType2 == null) {
        tempUpdate = false;
        notInput = '분류';
      }
      if (this.selectedType1 === '' || this.selectedType1 == null) {
        tempUpdate = false;
        notInput = '유형';
      }

      if (!tempUpdate) {
        this.$bvModal.msgBoxOk(`${notInput}를(을) 입력해주세요`, {
          title: '필수 입력 확인',
          okVariant: 'danger',
          centered: true,
        });
        return;
      }
      //파일사이즈 확인
      if (this.attachedFile !== null) {
        const filesize = (this.attachedFile.size / 1024 / 1024).toFixed(4);
        if (filesize > 10) {
          // console.log('100메가 이상');
          this.$bvModal.msgBoxOk('10M 이하의 파일만 첨부 가능합니다.', {
            okVariant: 'danger',
            centered: true,
          });
          this.attachedFile = null;
          return;
        }
      }

      const qnaDto = {
        userName: this.userName,
        userPhone: this.userPhone,
        userEmail: this.userEmail,
        type1: this.selectedType1,
        type2: this.selectedType2,
        desc: this.desc,
      };
      console.log(qnaDto);
      const url = `/api/SendQnaMail`;
      const formData = new FormData();
      formData.append('qnaDto', new Blob([JSON.stringify(qnaDto)], {type: 'application/json'}), 'dto.txt');
      formData.append('file', this.attachedFile);
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      await axios
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(async response => {
          const result = await self.$bvModal.msgBoxOk('고객의 소리가 접수되었습니다', {
            title: '성공',
            okVariant: 'success',
            centered: true,
          });
          self.$router.push('/');
        })
        .catch(function (error) {
          if (error.response.status === 403) {
            self.$bvModal.msgBoxOk('스크립트 및 html을 입력할수 없습니다', {
              title: '입력오류',
              okVariant: 'danger',
              okTitle: '확인',
              centered: true,
            });
            return;
          }
          self.$bvModal.msgBoxOk('고객의 소리 접수도중 오류발생', {
            title: '접수도중 오류발생',
            centered: true,
          });
        })
        .finally(() => {
          this.sendingMail = false;
        });
    },
    space1Changed() {
      //['클럽하우스', '포도호텔', '디아넥스'
      if (this.selectedType1 === '클럽하우스') {
        this.selectedType2 = this.space2Options[0][0];
      } else if (this.selectedType1 === '포도호텔') {
        this.selectedType2 = this.space2Options[1][0];
      } else if (this.selectedType1 === '디아넥스') {
        this.selectedType2 = this.space2Options[2][0];
      } else {
        this.selectedType2 = '';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.page-content {
  padding-bottom: 120px;
}

.contact-detail .box-gray {
  /*padding: 40px;*/
  background-color: #f5f5f5;
}

.contact-detail .txt-top {
  padding: 50px 20px 0px 20px;
  margin-bottom: 50px;
  font-size: 17px;
  text-align: center;
  word-break: keep-all;
}

.contact-detail .table-contact {
  width: 60%;
}

.contact-detail .table-contact th {
  width: 150px;
}

.table-join-form {
  /*width: 100%;*/
  margin: 0rem auto;
  padding: 1rem 0rem;
}

.table-join-form th {
  color: #333333;
  font-weight: normal;
}

textarea::placeholder {
  color: #8b8b8b;
  font-size: 0.9rem;
}

.grid-container.gutter-10 {
  margin-left: -5px;
  margin-right: -5px;
}

.grid-container > .col,
.grid-container > .row > .col {
  float: left;
  box-sizing: border-box;
}

.radio-style span::before {
  content: ' ';
  display: inline-block;
  margin-right: 6px;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 1px solid #c2c2c2;
  border-radius: 50%;
  box-sizing: border-box;
  vertical-align: top;
}

.radio-style input:checked + span::after {
  content: ' ';
  position: absolute;
  top: 4px;
  left: 4px;
  display: block;
  width: 10px;
  height: 10px;
  background-color: #666;
  border-radius: 50%;
}

.radio-style span {
  position: relative;
  display: inline-block;
  font-family: Arial, 'Nanum Gothic', sans-serif;
  letter-spacing: 0;
  cursor: default;
  line-height: 1;
  vertical-align: middle;
}

.radio-style {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
}

.f-ml {
  margin-left: 6px;
}

.textarea.t-medium {
  padding: 10px 15px;
  font-size: 15px;
  line-height: 1.6;
}

.textarea {
  display: block;
  padding: 14px 15px;
  width: 100%;
  border: 1px solid #c2c2c2;
  font-family: 'Nanum Gothic', sans-serif;
  color: inherit;
  font-size: 14px;
  line-height: 20px;
}

.datePickLayer {
  display: inline-block;
  width: 100%;
}

.left-right {
  display: flex;

  .left {
    width: 50%;
  }

  .right {
    width: 50%;
  }
}

.left-right-hope {
  margin-left: 1%;
  display: flex;

  .left {
    width: 48%;
    margin-right: 2%;
  }

  .right {
    width: 48%;
  }
}

.inguiry-button {
  background-color: #333333;
  color: #fff;
  height: 3rem;
}

.info-text {
  font-size: 0.8rem;
  color: #6d6d6d;
  line-height: 1.2rem !important;
  padding: 0rem;
}

.header {
  position: relative;
  width: 100%;

  > img {
    width: 100%;
  }

  .overlay {
    color: White;
    position: absolute;
    text-align: center;
    z-index: 12;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: lighter;
    letter-spacing: -0.1rem;
  }
}

.contentDesc {
  text-align: center;

  .title1 {
    margin-top: 3rem;
    margin-bottom: 5rem;
  }

  .title2 {
    margin-top: 3rem;
  }

  .title3 {
    margin-top: 1rem;
    margin-bottom: 5rem;
  }
}

@media (min-width: 992px) {
  .headerimage {
    width: 100%;
    margin: 0;
    content: url('~@/assets/images/common/Eventinquiry_headerimage.jpg');
  }
  .webTable {
    display: table;
  }
  .mobTable {
    display: none;
  }
  .table-join-form th {
    width: 30%;
    font-size: 1rem;
  }

  .table-join-form th,
  .table-join-form td {
    padding-top: 1rem;
    //line-height: 3rem;
  }
}

@media (max-width: 992px) {
  .headerimage {
  }
  .header {
    position: relative;
    width: 100%;
    padding-top: 5rem;

    > img {
      width: 100%;
    }

    .overlay {
      padding-top: 5rem;
      color: White;
      position: absolute;
      text-align: center;
      z-index: 12;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: lighter;
      letter-spacing: -0.1rem;
    }
  }
  .webTable {
    display: none;
  }
  .mobTable {
    display: table;
  }
  .table-join-form th {
    padding-top: 1rem;
  }
  .table-join-form th,
  .table-join-form td {
    width: 100%;
  }
}
</style>
